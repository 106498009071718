export enum RIDCContractorStatus {
    SENT = "SENT",
    OPENED = "OPENED",
    BEING_FILLED_OUT = "BEING_FILLED_OUT",
    FILLED_OUT = "FILLED_OUT",
    PROCESSING = "PROCESSING",
    REGISTERED = "REGISTERED",
    PENDING_FOR_W9 = "PENDING_FOR_W9",
    COMPLETED = "COMPLETED"
}
