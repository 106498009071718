export enum PersonSex {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER',
    UNDEFINED = 'UNDEFINED'
}

export enum StateIDSubtype {
    ID = 'ID',
    LEARNER_PERMIT = 'LEARNER_PERMIT',
    DRIVER_LICENSE = 'DRIVER_LICENSE'
}
export enum ResidencyStatus {
    CITIZEN = "CITIZEN",
    PERMANENT_RESIDENT = "PERMANENT_RESIDENT",
    NON_CITIZEN = "NON_CITIZEN"
}

export enum SSNType {
    NO_RESTRICTIONS = 'NO_RESTRICTIONS',
    WORK_WITH_AUTHORIZATION = 'WORK_WITH_AUTHORIZATION',
    NOT_FOR_WORK = 'NOT_FOR_WORK'
}