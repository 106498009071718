












































































import {Component, Vue} from 'vue-property-decorator';
import { PublicRIDCContractorDTO } from "@/dto/request/ridc/PublicRIDCContractorDTO";
import {namespace} from "vuex-class";
import RIDCService from "@/services/request/RIDCService";
import {ResidencyStatus} from "@/constants/PersonConstants";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {RIDCContractorStatus} from "@/constants/request/RIDCConstants";
import { processError } from "@/utils/ComponentUtils";

const AppModule = namespace("App");

@Component({
  components: {SingleFileHolder},
  computed: {
    RIDCContractorStatus() {
      return RIDCContractorStatus
    },
    ResidencyStatus() {
      return ResidencyStatus
    }
  }
})
export default class PublicContractorRIDC extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  request: PublicRIDCContractorDTO | null = null;

  mounted() {
    this.loadRequest();
  }

  loadRequest() {
    this.startLoading();
    RIDCService.getContractorByPublicId(this.id).then(
      ok => {
        this.request = ok.data;
        this.stopLoading();
      },
      err => {
        this.stopLoading();
        processError(err, this);
      }
    )
  }

  save() {
    if(!this.request){
      return;
    }
    this.startLoading();
    RIDCService.saveContractorByPublicId(this.id, this.request).then(
      ok => {
        this.stopLoading();
        this.loadRequest();
      },
      err => {
        this.stopLoading();
        processError(err, this);
      }
    )
  }

  get id() {
    return this.$route.params.id;
  }

}
