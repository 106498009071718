import axios from 'axios';
import {
    REQUEST_RIDC_CONTRACTORS_ENDPOINT,
    REQUEST_RIDC_ENDPOINT,
    REQUEST_RIDC_PUBLIC_CONTRACTORS_ENDPOINT,
    REQUEST_RIDC_PUBLIC_ENDPOINT
} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import CreateRIDCPayload from "@/dto/request/ridc/CreateRIDCPayload";
import {PublicRIDCContractorDTO} from "@/dto/request/ridc/PublicRIDCContractorDTO";
import RIDC_DTO from "@/dto/request/ridc/RIDC_DTO";
import CreateRIDCContractorPayload from "@/dto/request/ridc/CreateRIDCContractorPayload";
import CompleteRIDCContractorPayload from "@/dto/request/ridc/CompleteRIDCContractorPayload";

class RIDCService {

    create(payload: CreateRIDCPayload) {
        return axios.post(REQUEST_RIDC_ENDPOINT, payload, {headers: authHeader()});
    }

    getByPublicId(id: string) {
        return axios.get<RIDC_DTO>(`${REQUEST_RIDC_PUBLIC_ENDPOINT}/${id}`);
    }

    addContractor(id: string, payload: CreateRIDCContractorPayload) {
        return axios.post(`${REQUEST_RIDC_PUBLIC_ENDPOINT}/${id}/contractors`, payload);
    }

    getById(id: number) {
        return axios.get<RIDC_DTO>(`${REQUEST_RIDC_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    getContractorByPublicId(id: string) {
        return axios.get<PublicRIDCContractorDTO>(`${REQUEST_RIDC_PUBLIC_CONTRACTORS_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    saveContractorByPublicId(id: string, payload: PublicRIDCContractorDTO){
        return axios.put(`${REQUEST_RIDC_PUBLIC_CONTRACTORS_ENDPOINT}/${id}`, payload);
    }

    completeFillingContractors(id: string) {
        return axios.put(`${REQUEST_RIDC_PUBLIC_ENDPOINT}/${id}/status/filled`);
    }

    completeContractorRegistration(id: string, payload: CompleteRIDCContractorPayload) {
        return axios.put(`${REQUEST_RIDC_CONTRACTORS_ENDPOINT}/${id}`, payload,{headers: authHeader()});
    }

}

export default new RIDCService();
